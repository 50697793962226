
(function($) {
    
    svg4everybody();

    $('[data-catcher]').on('click', function(event) {
        event.preventDefault();
        showCatcher();
    });

    $('[data-video-catcher]').on('click', function(event) {
        event.preventDefault();
        showVideoCatcher(3000, this);
    });

    $('[data-catcher-close]').on('click', function(event) {
        event.preventDefault();
        $('#catcher').removeClass('is-active');
    });

    

    function showCatcher() {
         $('#catcher').addClass('is-active');
    }


    function showVideoCatcher(delay, item) {
        $('html,body').animate({scrollTop:0},200);
        $('#catcher-video').removeClass('is-active');

        $('.video-player__control').addClass('is-active').removeClass('is-hidden');
        $('.video-player-loading').addClass('is-active').removeClass('is-hidden');
        if (item.getAttribute('href')) {
            $('.video-player__image').attr('src', item.getAttribute('href'));
        }
        setTimeout(function() {
                $('.video-player__control').addClass('is-hidden').removeClass('is-active');
                $('.video-player-loading').addClass('is-hidden').removeClass('is-active');
                $('#catcher-video').addClass('is-active');
        }, delay);
         
    }

    
    $('.carousel-videos').slick({
      dots: true,
      infinite: true,
      speed: 800,
      slidesToShow: 2,
      slidesToScroll: 2,
      mobileFirst: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1024,
          settings: {
              arrows: true,
              slidesToShow: 5,
              slidesToScroll: 5,
          }
        },
        {
          breakpoint: 1440,
          settings: {
              arrows: true,
              slidesToShow: 6,
              slidesToScroll: 6,
          }
        }

      ]
    });
  

}(jQuery));